import React from 'react'
/* import HeroBgAnimation from '../HeroBgAnimation' */
import { HeroContainer, HeroBg, HeroLeftContainer, Img, HeroRightContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle, Wrapper } from './HeroStyle'
import MainImg from '../../images/yashvardhan.jpg'
/* import Typewriter from 'typewriter-effect'; */
import { Bio } from '../../data/constants';


const HeroSection = () => {

    const [width, setWidth] = React.useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    {/* <HeroBgAnimation /> */}
                    <HeroRightContainer id="Right">
                        {!isMobile && <Img src={MainImg} alt="yashvardhan-img" />}
                    </HeroRightContainer>
                </HeroBg>
                <HeroInnerContainer >
                    <HeroLeftContainer id="Left">
                        <Title>Hello!</Title>
                        <TextLoop><Span> I'm {Bio.name}, <br /> a Software Development Engineer based in Mumbai, India </Span></TextLoop>
                        {/* <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                /> 
                            </Span> */}
                        {/* <TextLoop>
                            I am a
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop> */}
                        {/* <SubTitle>A bit about me:</SubTitle> */}
                        <SubTitle>{Bio.description}</SubTitle>
                        {/* <SubTitle>{Bio.additionalDesc}</SubTitle> */}
                        {/* <ResumeButton href={Bio.resume} target='display'>Resume</ResumeButton> */}
                    </HeroLeftContainer>
                    <HeroRightContainer id="Right">
                    </HeroRightContainer>

                </HeroInnerContainer>

            </HeroContainer>
        </div>
    )
}

export default HeroSection