export const Bio = {
  name: "Yashvardhan",
  roles: [
    "Software Development Engineer",
    "Full Stack Developer",
    "Problem Solver",
    "IB Enthusiast",
    "Programmer",
  ],
  role:
    "Software Development Engineer",
    /* "Full Stack Developer",
    "Problem Solver",
    "IB Enthusiast",
    "Programmer", */
  /* description:
    "With over 2 years of hands-on experience in designing, constructing and maintaining high-scale products within the FinTech space under Agile SDLC, I posses strong analytical skills and a focus on developmental design patterns, data flow logic, and robust system architecture. I excel in grasping complex concepts quickly and being able to break them into small sub-concepts to be able to elaborate better. " +
    "Having worked with cross functional product teams and integrating their solutions into core systems, I am " + 
    "a vibrant young leader that likes to leverage intersection of strategy, analytics and code to achieve the desired objective.", */
    description: "With more than 2 years of hands-on experience in designing, engineering and maintaining high-scale prodducts in the FinTech space, " +
    "my expertise lies in analyzing intricate systems, focusing on developmental design patterns, optimizing data flow logic using suitable data structures and algorithms, delivering resilient features catering to business requirements and integrating solutions into core systems. I thrive on deciphering complex concepts swiftly, using perception and cognitive imagination to break them down into manageable chuncks for clearer elucidation. "+
    "As a dynamic young leader working with cross-functional teams, I'm driven by the convergence of strategy, analytics, organisation and code to achieve the desired objective effectively. I am currently exploring the potential applications of Generative AI in banking, strategic investments and healthcare.",
    additionalDesc : "",
  github: "https://github.com/yashdevs26",
  resume:
    "https://tinyurl.com/getDocument",
  linkedin: "https://www.linkedin.com/in/yashvardhan-dwivedi",
  twitter: "",
  insta: "",
  facebook: "",
};

export const skills = [
  {
    title: "Backend",
    skills: [
      {
        name: "Spring Boot",
        image: "https://res.cloudinary.com/startup-grind/image/upload/c_fill,dpr_2.0,f_auto,g_center,h_1080,q_100,w_1080/v1/gcs/platform-data-dsc/events/spring-boot-1_5zDxm9B.jpg",
      },
      {
        name: "JPA Hibernate",
        image:"https://miro.medium.com/v2/resize:fit:400/0*jba3dz1j64rfhl5i.jpg"
      },
      {
        name: "Spring Security",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpf2hnyyUKLOm_eBGBhJ5nYPycFQZZpvrPTryf95-q4g&s",          
      },
      {
        name: "Spring Core",
        image: "https://stackjava.com/wp-content/uploads/2017/12/spring-core-logo.png",
      },
      {
        name: "Spring Cloud",
        image:"https://e4developer.com/wp-content/uploads/2018/01/spring-cloud-logo.png"
      },
      {
        name: "JUnit",
        image:"https://avatars.githubusercontent.com/u/874086?s=200&v=4",
      },
      {
        name: "Mockito",
        image:"https://miro.medium.com/v2/resize:fit:310/1*UXCqf9LlfZELceiOf0bUgg.png",
      },
      {
        name: "Micro Services",
        image:"https://lh3.googleusercontent.com/proxy/IACOqBARv1k4ho5PfCn3sitg5HoLxR7D_Uu2maWVCUMP0S4_EMFdYg3uwXqL2dMpfjVWfrTmHhQdVrbqoF2rRW1WS1nHMLJWjWmvyuN_SkfeGpf-CnKq"
      },
      {
        name: "Apache Struts",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL8CQwsYcXgkd6WRufw4ivXkhzzYOvmKDX_7BgV9EaZg&s",
      }
    ],
  },
  {
    title: "Frontend",
    skills: [
      {
        name: "React",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Axios",
        image:
          "https://miro.medium.com/v2/resize:fit:1200/1*cQ8JTEvKMKaBhovYI2mncQ.png"
      },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
    ],
  },
  {
    title: "Database",
    skills: [
      {
        name: "Microsoft SQL Server",
        image:
          "https://w7.pngwing.com/pngs/338/123/png-transparent-microsoft-sql-server-sql-server-management-studio-database-transact-sql-microsoft-angle-white-text.png",
      },
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
      {
        name: "MySQL",
        image:"https://pngimg.com/uploads/mysql/small/mysql_PNG23.png",
      }
    ],
  },
  {
    title: "Cloud",
    skills: [
      {
        name: "Amazon Web Services",
        image:"https://i.pinimg.com/736x/a7/c6/ed/a7c6ede40689fe04bb72e221945f1a12.jpg",
      }
    ],
  },
  {
    title: "Languages",
    skills: [
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Kotlin",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "C++",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/800px-ISO_C%2B%2B_Logo.svg.png",
      },
      {
        name: "Python",
        image:"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png"
      },
    ],
  },
  {
    title: "Toolset",
    skills: [
      {
        name: "Apache Kafka",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFNkMpJyKxBntykJE_PNuTpQiRrajj-VcfpuXSsk_nAA&s",
      },      
      {
        name: "Git",
        image:
          "https://e7.pngegg.com/pngimages/713/558/png-clipart-computer-icons-pro-git-github-logo-text-logo-thumbnail.png"
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Tortoise Subversion",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHcnrHDzZHRUtWCAD1PiTUFS77moZvQhiPQA&s"
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
      {
        name: "Kubernetes",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv8nuXGA9Ci1h0dRX18z3BVdoMV6FAnIjnHCYyCT5raA&s",
      },
      {
        name: "Jenkins",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvPeWc7hK0shMvZF84e4usNbZ7MMRDisgdxaoQfM727w&s"
      },
      {
        name: "Figma",
        image:
          "https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/3000/figma-logo-512.png"
      },
    ],
  },
  /* {
    title: "Others",
    skills: [
      {
        name: "Scrum",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzRciutpgyoGfV1DDQHaRuC7UUdaRWll4Ljkjt9uzfvg&s",
      },
      {
        name: "DevOps",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzRciutpgyoGfV1DDQHaRuC7UUdaRWll4Ljkjt9uzfvg&s",
      },
      {
        name: "Agile",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzRciutpgyoGfV1DDQHaRuC7UUdaRWll4Ljkjt9uzfvg&s",
      },
      {
        name: "Agile",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzRciutpgyoGfV1DDQHaRuC7UUdaRWll4Ljkjt9uzfvg&s",
      },
      {
        name: "Agile",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzRciutpgyoGfV1DDQHaRuC7UUdaRWll4Ljkjt9uzfvg&s",
      }
    ],
  }, */
];

export const experiences = [
  {
    id: 0,
/*     img: "https://yt3.googleusercontent.com/ytc/AIdro_nC1DUXH6qpJPtYq0R1A9_pNBgjFly33AyJA65H5xMIUg=s900-c-k-c0x00ffffff-no-rj",
    role: "Associate Java Developer",
    company: "Link Group ", */
    img: "https://discovertemplate.com/wp-content/uploads/2024/03/Mufg.jpg",
    role: "Software Development Engineer - 1",
    company: "Mitsubishi UFJ Financial Group (MUFG)",
    date: "July 2022 - PRESENT",
    desc: "Working on a core SSoT product for Stock Option Plans, part of MUFG Corporate Markets for EMEA pipeline",
    responsibility: [
      "Engineer an exercise computation and reconciliation strategy by performing data validation,  creation of exercise requests with PEGA system, implementing core mathematical logic for pro rata and field calculations, data import and insertion logic and a discrepancy resolution procedure",
      "Publish SOAP APIs to by constructing function specific services and improving response times to an average of 2.2 seconds per request by optimizing data orchestration and computation logic",
      "Implement bulk data import and validation functionality by use of Apache POI and Multi-Threading in order to stage, validate and save data into the system",
      "Refactor the code-base with Spring Data JPA, SQL stored procedures, code redundancy reduction and optimising data handling logic with bidirectional association, OOP paradigms and utility functions creation which resulted in a performance enhancement by upto 45%",
      "Design front-end UI layer using Apache Struts and develop back-end functionality with Spring, which include data filtration and alteration, runtime processing, batch process scheduling and XLS/CSV file generation",
      "Improve memory utilisation by upto 25% with data structure refinements using Standard, Apache Commons, Google Guava libraries and Java 8 features",
      "Aid the delivery of over 33 core product modules for Share Plans product with an average post-development defect density of 0.0006 KLOC",
      "Contribute towards healthy delivery pipeline by assisting in bi-weekly deployments, monitoring documentation, conducting P2P reviews, performing code merge and integration and tracking items on Azure DevOps governed by SCRUM principles",
    ],
    skills: [
      "Java 8",
      "Spring Framework",
      "JPA Hibernate",
      "Transact SQL",
      "SOAP API",
      "Apache Struts",
      "Spring Scheduler",
      "Java XLS Parsing",
      "Azure Devops",
      "Jenkins",
      "Java Servlet Pages",
      "JavaScript",
      "Test Driven Development",
      "Agile SDLC with SCRUM",
      "Business Analytics",
      "CI/CD",
      "Team Management",
      "Leadership"
    ],
    doc: "",
  },
  /* {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/neurobit.jpeg?alt=media&token=1cacbb7b-e5ea-4efb-9b3d-f5a31b9564ad",
    role: "Frontend Engineer Intern",
    company: "Neurobit",
    date: "June 2023 - Present",
    desc: "Working on the frontend of the web application using ReactJS, Redux, and Material UI.",
    skills: [
      "ReactJS",
      "Redux",
      "NodeJs",
      "Material UI",
      "HTML",
      "CSS",
      "JavaScript",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/Screenshot%20from%202023-05-28%2023-20-46.png?alt=media&token=5570f995-e8f4-4f12-bb2f-73bcf4b20730",
  }, */
];

export const education = [
  {
    id: 0,
    img: "https://punetranscriptservices.com/wp-content/uploads/2020/06/Master.png",
    school: "University of Mumbai",
    date: "August 2018 - June 2022",
    grade: "CGPI: 9.50 out of 10 or GPA: 3.80 out of 4",
    desc: ["Publication Head for department's core student body.", 
      "Formulated and conducted innovative technical contests, quizzes and activities for students.",
      "Supervised magazine committee and published articles for the same. ",
      "Spearheaded organising committe for annual techfests for A.Y 2019 and 2020.",
      "Collaborated with junior committe and introducted digital marketing for College.",
      "Arranged and conducted industrial visits for Students.",
      "Acted as consultant for subsequent committee in A.Y. 2020 - 21."
    ],
    degree: "Bachelors in Engineering",
  },
  /* {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/methodist.png?alt=media&token=018a1b18-e2dd-4f34-8855-cff1b6b69fd3",
    school: "Methodist School, Dankuni",
    date: "Apr 2019 - Apr 2025",
    grade: "88.2%",
    desc: "I completed my class 12 high school education at Methodist School, Dankuni, where I studied Science with Computer Science.",
    degree: "ISC(XII), Science with Computer",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/methodist.png?alt=media&token=018a1b18-e2dd-4f34-8855-cff1b6b69fd3",
    school: "Methodist School, Dankuni",
    date: "Apr 2017 - Apr 2019",
    grade: "92.3%",
    desc: "I completed my class 10 education at Methodist School, Dankuni, where I studied Science with Computer Application.",
    degree: "ICSC(X), Science with Computer",
  }, */
];

export const projects = [
  {
    id: 9,
    title: "Opti Tracker",
    date: "",
    description:
      "Opti tracker is an application which would allow a user to track parameters such as tasks, activities and finances in such that they can regulate a balance of productivity and leasure in their daily lives. It would be a one stop solution for tracking the mentioned parameters so one may not need to download multiple apps and spend time tracking the same.",
    image:
      "https://raw.githubusercontent.com/yashdevs26/opti-tracker/main/opti%20tracker%20v0.1.4.png",
    tags: [
      "Spring Boot 3.2",
      "Spring Security",
      "Rest API",
      "Java Mail API",
      "HAL Explorer",
      "OAuth2 Resource Server for JWT",
      "Spring Data JPA",
      "AWS",
      "MySQL",
      "React",
      "Axios"
    ],
    category: "active",
    github: "https://github.com/yashdevs26/opti-tracker",
    webapp: "https://yashdevs.com/portfolio/optiTracker",
  },
  {
    id: 9,
    title: "Folio Scientist",
    date: "",
    description:
      "Folio Scientist is a microservice which takes certain trackable parameters for your mutual fund portfolio and using a custom market volatality algorithm suggest whether your returns are adequate towards your risk taken. Currently in Development.",
    image:
      "https://img.freepik.com/free-vector/coming-soon-text-abstract-sunrise-dark-background-with-motion-effect_157027-1073.jpg",
    tags: [
      "Spring Boot 3.2",
      "Spring Cloud",
      "Eureka",
      "AWS",
      "Github"
    ],
    category: "active",
    github: "",
    webapp: "",
  },
  {
    id: 9,
    title: "Perceptual Stock Analyzer",
    date: "",
    description:
      "A narrow AI algorithm that uses a robust combination of fundamental and technical analysis to give insights about a particualr stock. Studies publically available data and then recommends investment strategy. Currently in Development.",
    image:
      "https://img.freepik.com/free-vector/coming-soon-text-abstract-sunrise-dark-background-with-motion-effect_157027-1073.jpg",
    tags: [
      "Gen AI"
    ],
    category: "active",
    github: "",
    webapp: "",
  },
 /*  {
    id: 9,
    title: "Hostipal Management CRUD App",
    date: "",
    description:
      "A simple multi-database CRUD webapp made as part of our DBMS course during Engineering Cirriculum where one can details relating to doctors, patients and staff for efficcient information storage.",
    image:
      "https://user-images.githubusercontent.com/64485885/255202416-e1f89b04-2788-45b0-abc2-9dec616669e2.png",
    tags: [
      "PHP",
      "HTML/CSS",
      "BootStrap",
      "MySQL",
      "XAMPP"
    ],
    category: "archived",
    github: "https://github.com/rishavchanda/Trackify",
    webapp: "https://trackify.duckdns.org",
  },
  {
    id: 9,
    title: "Payment Gateway Demo",
    date: "",
    description:
      "A simple app made as POC which integrates Razorpay as its payment gateway, operasting in Test Mode.",
    image:
      "https://user-images.githubusercontent.com/64485885/255202416-e1f89b04-2788-45b0-abc2-9dec616669e2.png",
    tags: [
      "BootStrap",
      "API Integration",
      "HTML/CSS",
      "Heroku"
    ],
    category: "archived",
    github: "https://github.com/yashdevs26/opti-tracker",
    webapp: "https://github.com/yashdevs26/opti-tracker",
  },
  {
    id: 0,
    title: "SaaS for FMCG Supply Chain Management",
    date: "",
    description:
      "Our Final year Project which is a POC for Decentralised Asset Tracking System. The software as a service (SaaS) for vaccine tracking will "+
      "maintain the interactions through a decentralized application and will track the vaccines "+
      "shipments through the blockchain network. The ethereum blockchain network will handle the "+
      "transactions as well as store the secured data onto the blockchain. This supply chain "+
      "management system that will use blockchain network that will be capable of handling endpoint-"+
      "based tracking for wide variety of vendors.",
    image:
      "https://user-images.githubusercontent.com/64485885/234602896-a1bd8bcc-b72b-4821-83d6-8ad885bf435e.png",
    tags: ["React JS",  "HTML/CSS", "LeafLet Routing Machine", "Firebase", "Node JS",  "Solidity", "MIT App Inventor", "Metamask", "Ganache", "Ether JS"],
    category: "archived",
    github: "https://github.com/manalavi/dAssetTrackingSystem/tree/main",
    webapp: "",
    member: [
      {
        name: "Yashvardhan Dwivedi",
        img: "https://avatars.githubusercontent.com/u/46245660?v=4",
        linkedin: "",
        github: "https://github.com/yashdevs26/",
      },
      {
        name: "Vinay Manala",
        img: "https://avatars.githubusercontent.com/u/62110273?v=4",
        linkedin: "",
        github: "",
      },
      {
        name: "Om Pancholi",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, */
  /* {
    id: 1,
    title: "Vexa",
    date: "Oct 2022 - Present",
    description:
      "Designed and developed the Vexa project, a project management app that helps users and teams stay organized and on track. Implemented key features such as task tracking, team collaboration, and time tracking to improve productivity and project outcomes and also invite team/project members. The plan is to add a community of users where they can find developers and other team members and chat system implementation.",
    image:
      "https://user-images.githubusercontent.com/64485885/234916413-96296f13-fe4b-4cc4-b215-e72bd7c27928.png",
    tags: [
      "React Js",
      "MongoDb",
      "Node Js",
      "Express Js",
      "Redux",
      "NodeMailer",
    ],
    category: "web app",
    github: "https://github.com/rishavchanda/Project-Management-App",
    webapp: "https://vexa-app.netlify.app/",
  },
  {
    id: 2,
    title: "Brain Tumor Detection",
    date: "Jan 2023 - Mar 2023",
    description:
      "Preprocessed and augmented the dataset to improve model accuracy, trained the model, created API using model and Python, and used React web app for the project's front end. Achievements: Achieved an accuracy of 99.2% to accurately detect brain tumors from medical images.",
    image:
      "https://github.com/rishavchanda/Brain-Tumor-Detection/raw/main/Readme_resource/Image2.png",
    tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
    category: "machine learning",
    github: "https://github.com/rishavchanda/Brain-Tumor-Detection",
    webapp: "https://brain-tumor.netlify.app/",
    member: [
      {
        name: "Rishav Chanda",
        img: "https://avatars.githubusercontent.com/u/64485885?v=4",
        linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
        github: "https://github.com/rishavchanda/",
      },
      {
        name: "Upasana Chaudhuri",
        img: "https://avatars.githubusercontent.com/u/100614635?v=4",
        linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
        github: "https://github.com/upasana0710",
      },
    ],
  },
  {
    id: 3,
    title: "Buckoid",
    date: "Dec 2021 - Apr 2022",
    description:
      "App Is Currently In Playstore 100+ Downloads. This Project proposes an “Expense Tracking App”. Keep track of your personal expenses and compare them to your monthly income with the budget planner. It has Google Drive Cloud API for Backup of User Room Database. Made with Kotlin in MVVM Architecture & Live Data.",
    image:
      "https://camo.githubusercontent.com/fbf405040ae86b5d4a40f24e4ac034982cb8c0e2d850560ba64527997b258be8/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f6c6f67696e2d65613565322e61707073706f742e636f6d2f6f2f4255434b4f49442532302831292e706e673f616c743d6d6564696126746f6b656e3d32653735376235372d323964372d346263612d613562322d653164346538313432373435",
    tags: ["Kotlin", "MVVM", "Room Database", "Google Drive Cloud API"],
    category: "android app",
    github: "https://github.com/rishavchanda/Buckoid-Android-App",
    webapp: "https://play.google.com/store/apps/details?id=com.rishav.buckoid",
  },
  {
    id: 10,
    title: "Job Finding App",
    date: "Jun 2023 - Jul 2023",
    description:
      "A Job Finding App made with React Native, Axios. Users can search for any job coming from API and apply there.",
    image:
      "https://user-images.githubusercontent.com/64485885/255237090-cf798a2c-1b41-4bb7-b904-b5353a1f08e8.png",
    tags: ["React Native", "JavaScript", "Axios"],
    category: "android app",
    github: "https://github.com/rishavchanda/Job-finder-App",
    webapp: "https://github.com/rishavchanda/Job-finder-App",
  },
  {
    id: 4,
    title: "Whatsapp Clone",
    date: "Jul 2021",
    description:
      "A WhatsApp clone made with React JS, Firebase, and Material UI. It has Phone Authentication, Real-time Database. It has a chat room where users can chat with each other. It has a sidebar where users can see all the chat rooms and can create a new chat room. It has a login page where users can log in with their Google account.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-rishav.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
    tags: ["React Js", "Firebase", "Firestore", "Node JS"],
    category: "web app",
    github: "https://github.com/rishavchanda/Whatsapp-Clone-React-Js",
    webapp: "https://whatsapp-clone-rishav.web.app",
  },
  {
    id: 5,
    title: "Todo Web App",
    date: "Jun 2021",
    description:
      " A Todo Web App made with React JS, Redux, and Material UI. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the tasks and can create a new task. It has a calendar where users can see all the tasks on a particular date. It has a search bar where users can search for a particular task.",
    image:
      "https://camo.githubusercontent.com/a328255ad96f861f57d25096d28018ab2656c689a1456b0d145764009bed2d1a/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f74253230283938292e706e673f616c743d6d6564696126746f6b656e3d33643335646366322d626666322d343730382d393031632d343232383866383332386633",
    tags: ["React Js", "Local Storage", "AWS Auth", "Node JS"],
    category: "web app",
    github: "https://github.com/rishavchanda/Todo-Web-App",
    webapp: "https://rishav-react-todo.netlify.app/",
  },
  {
    id: 6,
    title: "Breaking Bad",
    date: "Jun 2021",
    description:
      "A simple react app that shows the characters of the famous TV series Breaking Bad. It uses the Breaking Bad API to fetch the data. It also has a search bar to search for a particular character.",
    image:
      "https://camo.githubusercontent.com/cd07010cbeb90cb1b43a5d6162784326aef02210ef7d41a0f9ae043b3e392378/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f7425323028313534292e706e673f616c743d6d6564696126746f6b656e3d65613439383630632d303435362d343333342d616435372d336239346663303333363263",
    tags: ["React Js", "API", "Axios", "Node JS"],
    category: "web app",
    github: "https://github.com/rishavchanda/Breaking-Bad",
    webapp: "https://breaking-bad-webapp.netlify.app",
  },
  {
    id: 7,
    title: "Quiz App",
    date: "Dec 2020 - Jan 2021",
    description:
      "A android quiz app made with Java and Firebase. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the quiz categories and can create a new quiz. It has a leaderboard where users can see the top 10 scorers. It has a search bar where users can search for a particular quiz.",
    image:
      "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
    tags: ["Java", "Android Studio", "Firebase", "Google Auth"],
    category: "android app",
    github: "https://github.com/rishavchanda/Quiz-Earn",
    webapp: "https://github.com/rishavchanda/Quiz-Earn",
  },
  {
    id: 8,
    title: "Face Recognition",
    date: "Jan 2021",
    description:
      "A Face recognition python app made with OpenCV. It uses face_recognition library to detect faces. It uses the webcam to detect faces. It also has a search bar to search for a particular face.",
    image:
      "https://camo.githubusercontent.com/a8b3e1ebf26d4c95f75bc6671189a3590eb67eb8332d7c7452492990e731efb9/68747470733a2f2f77617265686f7573652d63616d6f2e696e67726573732e636d68312e707366686f737465642e6f72672f356137383335396561323762643261633232336437656662306639303831306437373930383436312f363837343734373037333361326632663633366336663735363432653637363937343638373536323735373336353732363336663665373436353665373432653633366636643266363137333733363537343733326633383339333633363339333232663332333433343333333033333339333832663333333636363330363533333636333032643331333336333632326433313331363533373264333833323335333832643334363433303633333936333635333136353334333133393265363736393636",
    tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
    category: "machine learning",
    github: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
    webapp: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  }, */
];

/* export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
]; */
