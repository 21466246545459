import styled from 'styled-components';
/* import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter'; */
import LinkedInIcon from '@mui/icons-material/LinkedIn';
/* import InstagramIcon from '@mui/icons-material/Instagram'; */
import GithubIcon from '@mui/icons-material/GitHub';
import OutlookIcon from '@mui/icons-material/Email';
import ResumeIcon from '@mui/icons-material/DocumentScannerRounded';
import { Bio } from '../../data/constants';
import React from 'react'

const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  //background: linear-gradient(100.26deg, rgba(0, 102, 255, 0.05) 42.33%, rgba(150, 0, 225, 0.05) 127.07%);
`;


const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.h1`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.navbar};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 0rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 0rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

const LinkSpan = styled.a`
  display: inline-block;
  margin: 0 0rem;
  color: ${({ theme }) => theme.navbar};
  transition: color 0.2s ease-in-out;
  text-decoration: none; 
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const LinkSpanMob = styled.a`
  display: inline-block;
  margin: 0 0rem;
  color: ${({ theme }) => theme.navbar};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }

`;

function Footer() {

  const [width, setWidth] = React.useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo>Reach out to me via</Logo>
        <Nav>
          {/* <NavLink href="#about">About</NavLink> */}
          {/* <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#experience">Experience</NavLink>
          <NavLink href="#projects">Projects</NavLink> */}
          {/* <NavLink href="#education">Education</NavLink> */}
        </Nav>
        { !isMobile && <SocialMediaIcons>
          {/* <SocialMediaIcon href={Bio.facebook} target="display"><FacebookIcon /></SocialMediaIcon> */}
          {/* <SocialMediaIcon href={Bio.twitter} target="display"><TwitterIcon /></SocialMediaIcon> */}
          <SocialMediaIcon href={Bio.github} target="display"><GithubIcon /></SocialMediaIcon> <LinkSpan href={Bio.github} target="display"> &nbsp;github.com/yashdevs26 &nbsp; &nbsp; </LinkSpan>

          <SocialMediaIcon href={Bio.linkedin} target="display"><LinkedInIcon /></SocialMediaIcon><LinkSpan href={Bio.linkedin} target="display"> &nbsp;linkedin.com/yashvardhan-dwivedi &nbsp; &nbsp; </LinkSpan>

          <SocialMediaIcon><OutlookIcon /></SocialMediaIcon><LinkSpan> &nbsp;dwivediyashvardhan@outlook.com &nbsp; &nbsp; </LinkSpan>

          {/* <SocialMediaIcon href={Bio.resume} target="display"><ResumeIcon /></SocialMediaIcon><LinkSpan href={Bio.resume} target="display"> &nbsp;Resume &nbsp; &nbsp; </LinkSpan> */}
        </SocialMediaIcons> }

        {isMobile && <>
          <SocialMediaIcon href={Bio.github} target="display"></SocialMediaIcon> <LinkSpanMob href={Bio.github} target="display"> &nbsp;github.com/yashdevs26 &nbsp; &nbsp; </LinkSpanMob>

          <SocialMediaIcon href={Bio.linkedin} target="display"></SocialMediaIcon><LinkSpanMob href={Bio.linkedin} target="display"> &nbsp;linkedin.com/yashvardhan-dwivedi &nbsp; &nbsp; </LinkSpanMob>

          <SocialMediaIcon></SocialMediaIcon><LinkSpanMob> &nbsp;dwivediyashvardhan@outlook.com &nbsp; &nbsp; </LinkSpanMob>

          {/* <SocialMediaIcon href={Bio.resume} target="display"></SocialMediaIcon><LinkSpanMob href={Bio.resume} target="display"> &nbsp;Resume &nbsp; &nbsp; </LinkSpanMob> */}
         </> }
        <Copyright>
          &copy; 2024 yashdevs.com.
        </Copyright>

      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;