export const darkTheme = {
    /* bg:"#00CED1", */
    bg:"#FFFFF0",
    bgLight: "#FCFBF4",
    primary:"#854CE6",
    text_primary:"#1b2631",
    text_secondary:"#b1b2b3",
    navbar:"#002f28",
    card:"#f5ddce",
    card_light: '#FFFFF0',
    button:"#f5ddce",
    white:"#FFFFFF",
    black:"#000000",
}

/* export const lightTheme = {
    bg:"#FFFFFF",
    bgLight: "#f0f0f0",
    primary:"#be1adb",
    text_primary:"#111111",
    text_secondary:"#48494a",
    card:"#FFFFF0",
    button:"#5c5b5b",
} */